import { template as template_fa5f34a78e7343179777cfffe04ceb21 } from "@ember/template-compiler";
import icon from "discourse/helpers/d-icon";
const InputTip = template_fa5f34a78e7343179777cfffe04ceb21(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
