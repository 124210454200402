import { template as template_aa3392f339394049bf50ae8f2ad7e919 } from "@ember/template-compiler";
const FKControlMenuContainer = template_aa3392f339394049bf50ae8f2ad7e919(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
