import { template as template_8cdd748ed4904fd4b7644b21d561f285 } from "@ember/template-compiler";
const WelcomeHeader = template_8cdd748ed4904fd4b7644b21d561f285(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
