import { template as template_d9413ba4f6ea48b1ae5afaf743c0563d } from "@ember/template-compiler";
const FKText = template_d9413ba4f6ea48b1ae5afaf743c0563d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
